import {
    handleQueryResolve,
} from '../utils';
import {buildMapToField} from "../../kroll/utils";
// PatientID, GenericName = '', Strength = '', extendedWhere = 'rx.RxDate > DATEADD(month, -6, GetDate())'
export default function () {
// FIXME - need to set this up to work with the query for the blister pack 
   

        return this.query(`
        SELECT 
	ID as GroupID,
	Name,
	Inactive
  FROM [pharmacy].[dbo].[NH]
  WHERE Inactive = 0 or Inactive is null
        `).then(handleQueryResolve);
    
}